::-webkit-scrollbar {
  width: 14px;
}

::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: #DDDDDD;
}

.tooltip {
    font-size: 18px !important;
    max-width: 265px !important;
    opacity: 1 !important;
  }

.__react_component_tooltip .multi-line {
    text-align: start !important;
}

input[type=checkbox]:focus
{
    outline: 1px solid rgba(0, 0, 0, 0.2);
}

input[type=checkbox]:disabled
{
    background-color: #DDDDDD;
    border-color: #DDDDDD;
}

input[type=checkbox]
{
    border: 1px solid #000000;
    background-color: white;
    border-radius: 2px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 13.5px;
    min-width: 13.5px;
    height: 13.5px;
    position: relative;
}

input[type=checkbox]:checked
{
    background: url("data:image/svg+xml,%3Csvg width='8' height='5' viewBox='0 0 8 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 2.5L3.25 4.5L7 0.5' stroke='black' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") no-repeat scroll center center;
}